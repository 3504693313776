<template>
 <div :class="windowClass">
   <v-container>
        <v-row no-gutters>
      <v-col
        md="10"
        offset-md="1"
        class="loginbg"
         style="min-height: 700px !important;"
        
      >
      <v-row dense>
        <v-col md="6"  v-for="(item,i) in Games" :key="i">
              <v-card
              class="ma-4"
                
            >
          <v-img
            class="white--text"
            height="140px"
            :src="item.bg"
          >
            <div class="pa-2">
              <span style="float:right;" class="small-font">Draw No.: 8092</span>
              <span>{{item.game}}</span>
            </div>
            <div class="text-center">
              <v-btn color="secondary" dark class="title font-weight-black ma-1 shadow-text" fab small>38</v-btn>
                <v-btn color="secondary" dark class="title font-weight-black ma-1 shadow-text" fab small>38</v-btn>
                <v-btn color="secondary"  dark class="title font-weight-black ma-1 shadow-text" fab small>38</v-btn>
              </div>
              <div class="text-center">Jul 15, 2019 | 4pm Draw</div>
          </v-img>
          <v-card-actions>
            <!-- <v-btn
              color="orange"
              text
              small
            >
              View Resutls
            </v-btn> -->
            <v-spacer/>
            <v-btn
              small
              color="success"
              text
              @click="$router.push(item.page).catch(err => {})"
            >
              PLAY
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-col>
      </v-row>
       
      </v-col>
    </v-row>
   </v-container>
  </div>
</template>
<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
import {
  mapMutations
} from 'vuex'
  export default {
    mixins: [API_MIXINS],
    data: () => ({
      Games:[]
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
        } 
        this.Games =[{game: "3D", bg: this.$store.state.game_logo.three_num, page:'/3d-game'},
          {game: "2D",  bg: this.$store.state.game_logo.two_num, page:'/2d-game'},
          {game: "4D",  bg: this.$store.state.game_logo.two_num, page:'/4d-game'},
          {game: "SWER3",  bg: this.$store.state.game_logo.two_num, page:'/swer3-game'},
          {game: "SWER2",  bg: this.$store.state.game_logo.two_num, page:'/swer2-game'},
          {game: "3D LOCAL",  bg: this.$store.state.game_logo.two_num, page:'/3dlocal-game'},
          {game: "2D LOCAL",  bg: this.$store.state.game_logo.two_num, page:'/2dlocal-game'},
          {game: "PICK 3",  bg: this.$store.state.game_logo.two_num, page:'/pick3-game'},
          {game: "STL PARES",  bg: this.$store.state.game_logo.two_num, page:'/stlpares-game'},
          {game: "STL LOCAL",  bg: this.$store.state.game_logo.two_num, page:'/stllocal-game'},
      ]
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      
    },
  }
</script>